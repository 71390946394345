import axios from "axios";
import { ustEffect, useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { budgetItemState } from "../recoil/atoms/budgetItemState";
import { accountItemState } from "../recoil/atoms/accountItemState";
import { expenseItemState } from "../recoil/atoms/expenseItemState";

import MCard from '../components/Budget/MCard';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const apime_auth = process.env.REACT_APP_APIME_AUTH;

export default function Budget() {
    const [budgetItems, setBudgetItems] = useRecoilState(budgetItemState);
    const [, setAccountItems] = useRecoilState(accountItemState);
    const [, setExpenseItems] = useRecoilState(expenseItemState);


    const getData = async () => {
        axios.all([
            getWhooingAccountItem(), 
            getBudgetItems(),
            getExpenseItem()
        ])
        .then(axios.spread((account, budget, expense) => {
            setAccountItems(account.data.data);
            setBudgetItems(budget.data.data);
            setExpenseItems(expense.data.data);
        })).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);
    
    return (
        <>
        {budgetItems && budgetItems.map((budgetItem) => {
            return <MCard budget={budgetItem} getData={getData} />
        })}
        </>
    )
}

function getBudgetItems() {
    return axios.get("api/budget/items", {
        headers: {Authorization: `Bearer ${apime_auth}`},
    })
}

function getWhooingAccountItem() {
    return axios.get("api/whooing/accountItems", {
        headers: {Authorization: `Bearer ${apime_auth}`},
    })
}

function getExpenseItem() {
    return axios.get("api/whooing/expenseItems", {
        headers: {Authorization: `Bearer ${apime_auth}`},
    })
}