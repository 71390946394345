import { Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import Sms from "../pages/Sms";
import Pay from "../pages/Pay";
import Budget from "../pages/Budget";
import Loa from "../pages/Loa";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sms" element={<Sms />} />
      <Route path="/pay" element={<Pay />} />
      <Route path="/budget" element={<Budget />} />
      <Route path="/loa" element={<Loa />} />
    </Routes>
  );
}
