
import { selector } from 'recoil';

import { accountItemState } from '../atoms/accountItemState';

export const accountItemSelector = selector({
    key: 'accountItemSelector',
    get: ({ get }) => {
        
        const leftOption = [];
        const rightOption = [];
        const accountItem = get(accountItemState);

        return {
            '소비': {
                    leftOptions: [...accountItem['expenses']],
                    rightOptions: [...accountItem['assets'], 
                                ...accountItem['liabilities'] ]
                },
            '입금': {
                leftOptions: [...accountItem['assets']],
                rightOptions: [...accountItem['income']]
            },
            
            '출금': {
                leftOptions: [...accountItem['assets'],
                ...accountItem['expenses']],
                rightOptions: [...accountItem['assets']]
            },
            
            '이체': {
                leftOptions: [...accountItem['assets']],
                rightOptions: [...accountItem['assets']]
            },
            
            '정산': {
                leftOptions: [...accountItem['liabilities']],
                rightOptions: [...accountItem['assets']]
            },
            
        };
    },
  });