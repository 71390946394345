import Header from "./components/Header";
import Router from "./components/Routes";
import { Divider } from "@mui/material";
import Footer from "./components/Footer";

import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <Header />
      <Divider />
      <Router />
      <Divider />
      <Footer sx={{ mt: 8, mb: 4 }} />
    </RecoilRoot>
  );
}

export default App;
