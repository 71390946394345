import * as React from "react";
import Typography from "@mui/joy/Typography";

export default function Loa() {
  return (
    <Typography
      level="h1"
      sx={{
        margin: "1rem",
      }}
    >
      추가
    </Typography>
  );
}
