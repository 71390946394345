import * as React from "react";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/joy/Typography";
import Payment from "@mui/icons-material/Payment";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";

import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Autocomplete from "@mui/joy/Autocomplete";

import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import Divider from "@mui/joy/Divider";

import axios from "axios";
import { setTime, setComma } from "../../util/msg";

export default function PayItemCard(prop) {
  const [expenseItemList, setExpenseItemList] = React.useState([]);

  const expenseItems = prop.expenseItems;
  const leftOptions = prop.leftOptions;
  const rightOptions = prop.rightOptions;

  const [whooingValue, setWhooingValue] = React.useState({
    id: prop.payItem._id,
    entry_date: prop.payItem.createAt,
    item: prop.payItem.item,
    money: prop.payItem.amount,
    left: leftOptions.filter(
      (option) => option.title === prop.payItem.left_item
    )[0]?.title,
    right: rightOptions.filter(
      (option) => option.title === prop.payItem.right_item
    )[0]?.title,
    value: "",
    memo: "",
  });

  return (
    <Card
      variant="outlined"
      key={prop.payItem._id}
      sx={{
        margin: "1rem",
        maxHeight: "max-content",
        maxWidth: "80%",
        mx: "auto",
        // to make the demo resizable
        overflow: "auto",
        resize: "both",
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: "0.25rem", right: "0.25rem" }}
        onClick={() => {
          console.log(prop.payItem._id);
        }}
      >
        <Close />
      </IconButton>
      <Typography level="title-lg" startDecorator={<Payment />}>
        {prop.payItem.item} | {setComma(prop.payItem.amount)}원
      </Typography>
      <Divider inset="none" />
      <CardContent
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
          gap: 1.5,
        }}
      >
        <FormControl sx={{ gridColumn: "1/-1" }}>
          <Typography level="body-lg" align="right">
            SMS: {prop.payItem.left_item} | {prop.payItem.right_item}
          </Typography>
          <Typography level="body-lg" align="right">
            {setTime(prop.payItem.createAt)}
          </Typography>
        </FormControl>
        <FormControl sx={{ gridColumn: "1/-1" }}>
          <Typography level="body-lg"></Typography>
        </FormControl>
        <FormControl>
          <FormLabel>왼쪽 항목</FormLabel>
          <Autocomplete
            defaultValue={
              leftOptions.filter(
                (option) => option.title === prop.payItem.left_item
              )[0]
            }
            options={leftOptions}
            getOptionLabel={(leftOptions) => leftOptions.title}
            groupBy={(leftOptions) => leftOptions.section}
            onChange={(event) => {
              const v = event.target.textContent;
              setWhooingValue((prvState) => {
                return { ...prvState, left: v };
              });
              setExpenseItemList(expenseItems[v]);
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>오른쪽 항목</FormLabel>
          <Autocomplete
            defaultValue={
              rightOptions.filter(
                (option) => option.title === prop.payItem.right_item
              )[0]
            }
            options={rightOptions}
            getOptionLabel={(rightOptions) => rightOptions.title}
            groupBy={(rightOptions) => rightOptions.section}
            onChange={(event) => {
              const v = event.target.textContent;
              setWhooingValue((prvState) => {
                return { ...prvState, right: v };
              });
            }}
          />
        </FormControl>
        <FormControl sx={{ gridColumn: "1/-1" }}>
          <FormLabel>아이템</FormLabel>
          <Autocomplete
            options={expenseItemList || ""}
            onChange={(event) => {
              const v = event.target.textContent;
              setWhooingValue((prvState) => {
                return { ...prvState, value: v };
              });
            }}
          />
        </FormControl>
        <FormControl sx={{ gridColumn: "1/-1" }}>
          <FormLabel>메모</FormLabel>
          <Input
            placeholder="메모"
            onChange={(event) => {
              const v = event.target.value;
              setWhooingValue((prvState) => {
                return { ...prvState, memo: v };
              });
            }}
          />
        </FormControl>
        <CardActions sx={{ gridColumn: "1/-1" }}>
          <Button
            variant="solid"
            color="primary"
            onClick={async () => {
              try {
                await axios.post("/whooing/insert", whooingValue);
                prop.getData();
              } catch (e) {
                console.log(e);
              }
            }}
          >
            제출
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
