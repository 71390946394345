import Typography from "@mui/joy/Typography";

import Deposit from "./Deposit";

export default function DepositCard({
  headerName,
  payItems,
  leftOptions,
  rightOptions,
  expenseItems,
  getData,
}) {
  return (
    <>
      {payItems.filter((payItem) => {
        return payItem.category === headerName;
      }).length !== 0 && (
        <Typography
          level="h1"
          sx={{
            margin: "1rem",
          }}
        >
          {headerName}
        </Typography>
      )}
      {payItems
        .filter((payItem) => {
          return payItem.category === headerName;
        })
        .map((payItem) => {
          return (
            <Deposit
              payItem={payItem}
              leftOptions={leftOptions}
              rightOptions={rightOptions}
              expenseItems={expenseItems}
              getData={getData}
              key={payItem._id}
            />
          );
        })}
    </>
  );
}
