import * as React from "react";
import { useEffect } from "react";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/joy/Typography";
import Payment from "@mui/icons-material/Payment";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";

import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Autocomplete from "@mui/joy/Autocomplete";

import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import Divider from "@mui/joy/Divider";

import axios from "axios";
import { setTime, setComma } from "../../util/msg";

import { useRecoilValue, useRecoilState } from 'recoil';
import { accountItemSelector } from '../../recoil/selectors/accountItemSelector';

import { expenseItemState } from "../../recoil/atoms/expenseItemState";
import { budgetItemState } from "../../recoil/atoms/budgetItemState";

import { useNavigate } from 'react-router-dom';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const apime_auth = process.env.REACT_APP_APIME_AUTH;

export default function MCard(prop) {  
  const [budgetItems, setBudgetItems] = useRecoilState(budgetItemState);
  const [expenseItemStates, ] = useRecoilState(expenseItemState);
  const [expenseItemList, setExpenseItemList] = React.useState([]);
  const {leftOptions,
    rightOptions
  } = useRecoilValue(accountItemSelector)[prop.budget.category];

  const navigate = useNavigate();

  const [whooingValue, setWhooingValue] = React.useState({
    id: prop.budget.id,
    entry_date: prop.budget.created_at,
    item: prop.budget.item,
    money: prop.budget.amount,
    left_item: prop.budget.left_item,
    right_item: prop.budget.right_item,
    value: "",
    memo: "",
  });

  return (
    <Card
      variant="outlined"
      key={prop.budget.id}
      sx={{
        margin: "1rem",
        maxHeight: "max-content",
        maxWidth: "80%",
        mx: "auto",
        // to make the demo resizable
        overflow: "auto",
        resize: "both",
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}
        onClick={() => {
          console.log(prop.budget.id);
        }}
      >
        <Close />
      </IconButton>
      <Typography level="title-lg" startDecorator={<Payment />}>
        {prop.budget.category} | {prop.budget.item} | {setComma(prop.budget.amount)}원
      </Typography>
      <Divider inset="none" />
      <CardContent
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
          gap: 1.5,
        }}
      >
        <FormControl sx={{ gridColumn: "1/-1" }}>
          <Typography level="body-lg" align="right">
            SMS: {prop.budget.left_item} | {prop.budget.right_item}
          </Typography>
          <Typography level="body-lg" align="right">
            {setTime(prop.budget.created_at)}
          </Typography>
        </FormControl>
        <FormControl sx={{ gridColumn: "1/-1" }}>
          <Typography level="body-lg"></Typography>
        </FormControl>
        <FormControl>
          <FormLabel>왼쪽 항목</FormLabel>
          <Autocomplete
            defaultValue={
              leftOptions.filter(
                (option) => option.title === prop.budget.left_item
              )[0]
            }
            options={leftOptions}
            getOptionLabel={(leftOptions) => leftOptions.title}
            groupBy={(leftOptions) => leftOptions.section}
            onChange={(event) => {
              const v = event.target.textContent;
              setWhooingValue((prvState) => {
                return { ...prvState, left_item: v };
              });
              setExpenseItemList(expenseItemStates[v] ? expenseItemStates[v] : ["이체"] );
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>오른쪽 항목</FormLabel>
          <Autocomplete
            defaultValue={
              rightOptions.filter(
                (option) => option.title === prop.budget.right_item
              )[0]
            }
            options={rightOptions}
            getOptionLabel={(rightOptions) => rightOptions.title}
            groupBy={(rightOptions) => rightOptions.section}
            onChange={(event) => {
              const v = event.target.textContent;
              setWhooingValue((prvState) => {
                return { ...prvState, right_item: v };
              });
              setExpenseItemList(expenseItemStates[v] ? expenseItemStates[v] : ["이체", "입금"] );
            }}
          />
        </FormControl>
        <FormControl sx={{ gridColumn: "1/-1" }}>
          <FormLabel>아이템</FormLabel>
          <Autocomplete
            options={expenseItemList || ""}
            onChange={(event) => {
              const v = event.target.textContent;
              setWhooingValue((prvState) => {
                return { ...prvState, value: v };
              });
            }}
          />
        </FormControl>
        <FormControl sx={{ gridColumn: "1/-1" }}>
          <FormLabel>메모</FormLabel>
          <Input
            placeholder="메모"
            onChange={(event) => {
              const v = event.target.value;
              setWhooingValue((prvState) => {
                return { ...prvState, memo: v };
              });
            }}
          />
        </FormControl>
        <CardActions sx={{ gridColumn: "1/-1" }}>
          <Button
            variant="solid"
            color="primary"
            onClick={async () => {
              try {
                prop.getData();
                navigate('/budget');
              } catch (e) {
                console.log(e);
              }
            }}
          >
            제출
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}