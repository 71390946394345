import * as React from "react";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Payment from "@mui/icons-material/Payment";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";

import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Autocomplete from "@mui/joy/Autocomplete";

import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import Divider from "@mui/joy/Divider";

export default function PayItemAdd(prop) {
  const leftOptions = prop.leftOptions.filter((v) => v.section === "비용");
  const [itemAddList, setItemAddList] = React.useState({});

  function onClick() {
    console.log(itemAddList);
  }

  return (
    <Card
      variant="outlined"
      sx={{
        margin: "1rem",
        maxHeight: "max-content",
        maxWidth: "80%",
        mx: "auto",
        // to make the demo resizable
        overflow: "auto",
        resize: "both",
      }}
    >
      <Typography level="title-lg" startDecorator={<Payment />}>
        추가
      </Typography>
      <Divider inset="none" />
      <CardContent
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
          gap: 1.5,
        }}
      >
        <FormControl>
          <FormLabel>소비</FormLabel>
          <Autocomplete
            options={leftOptions}
            getOptionLabel={(leftOptions) => leftOptions.title}
            groupBy={(leftOptions) => leftOptions.section}
            onChange={(event) => {
              const v = event.target.textContent;
              setItemAddList((prvState) => {
                return { ...prvState, expense: v };
              });
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>아이템</FormLabel>
          <Input
            onChange={(event) => {
              const v = event.target.value;
              setItemAddList((prvState) => {
                return { ...prvState, item: v };
              });
            }}
          />
        </FormControl>
        <CardActions sx={{ gridColumn: "1/-1" }}>
          <Button variant="solid" color="primary" onClick={onClick}>
            제출
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
