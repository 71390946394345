import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function FooterComponent(props) {
  return (
    <Grid2>
      <Typography variant="body2" align="center" {...props}>
        {"Copyright © "}
        <Link color="inherit" href="https://inners.me/">
          inners.me
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Grid2>
  );
}
