import * as React from "react";
import Typography from "@mui/joy/Typography";
import { Grid } from "@mui/joy";

import axios from "axios";

import DepositCard from "../components/Pay/DepositCard";
import TransectionCard from "../components/Pay/TransectionCard";
import AddCard from "../components/Pay/AddCard";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default function Pay() {
  const [payItems, setPayItems] = React.useState([]);
  const [leftOptions, setLeftOptions] = React.useState([]);
  const [rightOptions, setRightOptions] = React.useState([]);
  const [expenseItems, setExpenseItems] = React.useState({});

  const getData = async () => {
    const allData = async () => {
      const result = await axios("/whooing/sections").then((res) => {
        let lefts = [];
        let rights = [];
        const dataObject = res.data;

        lefts.push(
          ...dataObject.expenses.map((v) => {
            return {
              title: v.title,
              section: "비용",
            };
          })
        );
        lefts.push(
          ...dataObject.assets.map((v) => {
            return {
              title: v.title,
              section: "자산",
            };
          })
        );
        lefts.push(
          ...dataObject.liabilities.map((v) => {
            return {
              title: v.title,
              section: "부채",
            };
          })
        );

        rights.push(
          ...dataObject.liabilities.map((v) => {
            return {
              title: v.title,
              section: "부채",
            };
          })
        );

        rights.push(
          ...dataObject.income.map((v) => {
            return {
              title: v.title,
              section: "수익",
            };
          })
        );

        rights.push(
          ...dataObject.assets.map((v) => {
            return {
              title: v.title,
              section: "자산",
            };
          })
        );

        return {
          left: lefts,
          right: rights,
        };
      });

      const payData = await axios("/sms/items").then((res) => {
        return res.data;
      });

      const expenseItems = await axios("/whooing/items").then(
        (res) => res.data
      );

      setLeftOptions(result.left);
      setRightOptions(result.right);
      setPayItems(payData);
      setExpenseItems(expenseItems);
    };
    allData();
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    leftOptions &&
    rightOptions &&
    payItems &&
    expenseItems && (
      <Grid>
        <DepositCard
          headerName={"소비"}
          payItems={payItems}
          leftOptions={leftOptions}
          rightOptions={rightOptions}
          expenseItems={expenseItems}
          getData={getData}
        />
        <DepositCard
          headerName={"출금"}
          payItems={payItems}
          leftOptions={leftOptions}
          rightOptions={rightOptions}
          expenseItems={expenseItems}
          getData={getData}
        />
        <TransectionCard
          headerName={"이체"}
          payItems={payItems}
          leftOptions={leftOptions}
          rightOptions={rightOptions}
          expenseItems={expenseItems}
          getData={getData}
        />
        <TransectionCard
          headerName={"입금"}
          payItems={payItems}
          leftOptions={leftOptions}
          rightOptions={rightOptions}
          expenseItems={expenseItems}
          getData={getData}
        />

        <Typography
          level="h1"
          sx={{
            margin: "1rem",
          }}
        >
          추가
        </Typography>
        <AddCard leftOptions={leftOptions} />
      </Grid>
    )
  );
}
