import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&.Mui-selected": {
      color: "#000000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function ScrollableTabsButtonForce() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        <StyledTab
          component={Link}
          orientation="vertical"
          to="/"
          label="HOME"
        />
        <StyledTab
          component={Link}
          orientation="vertical"
          to="/sms"
          label="SMS"
        />
        <StyledTab
          component={Link}
          orientation="vertical"
          to="/pay"
          label="PAY"
        />
        <StyledTab
          component={Link}
          orientation="vertical"
          to="/budget"
          label="BUDGET"
        />
        <StyledTab
          component={Link}
          orientation="vertical"
          to="/loa"
          label="LOA"
        />
        <StyledTab
          component={Link}
          orientation="vertical"
          to="/cal"
          label="CAL"
        />
      </StyledTabs>
    </Box>
  );
}
